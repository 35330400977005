import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseModalDirective } from '../../troi-base-modal/baseModal.component';
import { ModalService } from '../../troi-modals/modal.service';

@Component({
  selector: 'troi-easter-modal',
  templateUrl: './troi-easter-modal.component.html',
  styleUrls: ['./troi-easter-modal.component.scss'],
})
export class TroiEasterModalComponent extends BaseModalDirective {
  public modalObject: {
    eggIndex: number;
  };

  public get eggIndex(): number {
    return this.modalObject.eggIndex;
  }

  private _codeMap: { [key: number]: string } = {
    1: '2040504c93d9036f85e3e0883fbc72d4',
    2: '0c05e99e634f058bf5f207c18ef00f55',
    3: '3307c1e480d3cf282c9b117725804070',
  };

  public isLoading = true;

  constructor(
    public modalService: ModalService<{
      eggIndex: number;
    }>,
  ) {
    super(modalService);
    this.modalObject = this.modalService.object;

    this.registerFoundEgg();
  }

  private get eggHash(): string {
    return this._codeMap[this.eggIndex];
  }

  private _httpClient = inject(HttpClient);

  public registerState: 'new' | 'alreadyFound' | 'error' | 'unknown' = 'unknown';
  public foundAmount = 0;

  private async registerFoundEgg(): Promise<void> {
    const response = await firstValueFrom(
      this._httpClient.post<EggResponse>(environment.url + '/components/utils/eggs-for-user', {
        eggHash: this.eggHash,
      }),
    );

    if (response.success) {
      this.registerState = 'new';
    } else if (response.message === 'Egg already found') {
      this.registerState = 'alreadyFound';
    } else {
      this.registerState = 'error';
    }

    this.foundAmount = Math.min(response.data.foundEggs.length, response.data.maxEggs);
    this.isLoading = false;
  }
}

interface EggResponse {
  success: boolean;
  message: 'Egg already found' | 'Egg successfully registered' | 'Invalid or missing eggHash';
  data: {
    foundEggs: string[];
    maxEggs: number;
  };
}
