import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, inject, Input } from '@angular/core';
import { ModalService } from '../troi-modals/modal.service';
import { TroiEasterModalComponent } from './modal/troi-easter-modal.component';

@Component({
  selector: 'troi-easter',
  templateUrl: './troi-easter.component.html',
  styleUrls: ['./troi-easter.component.scss'],
})
export class TroiEasterComponent {
  @Input()
  public set instanceNumber(value: number | string) {
    this._instanceNumber = coerceNumberProperty(value);
  }
  public get instanceNumber(): number {
    return this._instanceNumber;
  }
  private _instanceNumber = 0;

  private _modalService: ModalService<{
    eggIndex: number;
  }> = inject(ModalService);

  public triggerModal(): void {
    this._modalService.object = {
      eggIndex: this.instanceNumber,
    };

    this._modalService.init(TroiEasterModalComponent, {}, {});
  }

  constructor() {
    this._checkToDestroy();
  }

  private _elementRef = inject(ElementRef<HTMLElement>);

  private _checkToDestroy(): void {
    const currentDate = new Date().getTime();
    const endDate = new Date(2025, 3, 30).getTime();

    if (currentDate > endDate) {
      this._elementRef.nativeElement.remove();
    }
  }
}
