<troi-modal-header>
  <div style="display: flex">
    <p style="margin: 0px">
      {{ 'Easter.modal.title' | translate }}
    </p>
  </div>
</troi-modal-header>
<troi-modal-content style="overflow-y: inherit">
  <troi-linear-progress class="mb-5" mode="indeterminate" *ngIf="isLoading"></troi-linear-progress>
  <div class="troi-easter__content" *ngIf="!isLoading">
    <!-- <p class="troi-easter__content--title">
      {{ 'Easter.modal.content.title' | translate }}
    </p> -->
    <p class="troi-easter__content--text" *ngIf="registerState === 'new'">
      <b>{{ 'Easter.modal.content.new' | translate }}</b>
    </p>
    <p class="troi-easter__content--text" *ngIf="registerState === 'alreadyFound'">
      <b>{{ 'Easter.modal.content.alreadyFound' | translate }}</b>
    </p>
    <p class="troi-easter__content--text" *ngIf="registerState === 'error'">
      <b>{{ 'Easter.modal.content.error' | translate }}</b>
    </p>
    <p class="troi-easter__content--text" *ngIf="foundAmount === 1">
      {{ 'Easter.modal.content.foundOne' | translate }}
    </p>
    <p class="troi-easter__content--text" *ngIf="foundAmount === 2">
      {{ 'Easter.modal.content.foundTwo' | translate }}
    </p>
    <p class="troi-easter__content--text" *ngIf="foundAmount === 3">
      {{ 'Easter.modal.content.foundThree' | translate }}
    </p>
  </div>
</troi-modal-content>
